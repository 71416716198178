define("discourse/plugins/discourse-plugin-vatprc/discourse/initializers/vatprc-pdf-secure", ["exports", "discourse/lib/ajax", "discourse/lib/plugin-api"], function (_exports, _ajax, _pluginApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const LockButton = _ref => {
    let {
      short,
      user
    } = _ref;
    const [locked, setLocked] = htmPreact.useState(false);
    const [canOperate, setCanOperate] = htmPreact.useState(false);
    const [success, setSuccess] = htmPreact.useState(false);
    htmPreact.useEffect(() => {
      (0, _ajax.ajax)(`/vatprc/uploads/${short}/secure`).then(data => {
        setLocked(data.secure);
        if ((data.user_id === user.id || user.staff) && user.can_mark_upload_watermark) {
          setCanOperate(true);
        }
      });
    });
    const onclick = htmPreact.useCallback(() => {
      setSuccess(false);
      (0, _ajax.ajax)(`/vatprc/uploads/${short}/secure`, {
        type: "PUT",
        data: {
          secure: !locked
        }
      }).then(data => {
        setLocked(data.secure);
        setSuccess(true);
        setTimeout(() => setSuccess(false), 2000);
      });
    });
    return htmPreact.html`<button
    class="p-1 m-1 hover:bg-gray-100 cursor-pointer ${success ? "text-green-700" : "text-gray-300 hover:text-black"}"
    title="Toggle Secure"
    type="button"
    disabled=${!canOperate}
    onclick=${onclick}
  >
    <svg class="w-4 h-4 text-inherit cursor-pointer fill-current">
      <use href="#${locked ? "lock" : "lock-open"}"></use>
    </svg>
  </button>`;
  };
  var _default = _exports.default = {
    name: "discourse-attachment-pdf-secure",
    initialize() {
      (0, _pluginApi.withPluginApi)("0.8.7", api => {
        api.decorateCookedElement((cooked, widget) => {
          if (!widget) {
            return;
          }
          const user = api.getCurrentUser();
          cooked.querySelectorAll("a").forEach((/** @type HTMLAnchorElement */elem) => {
            if (!elem.classList.contains("attachment")) {
              return;
            }
            if (!elem.href.startsWith("/uploads/short-url/")) {
              return;
            }
            if (!elem.href.endsWith(".pdf")) {
              return;
            }
            const short = elem.href.replace("/uploads/short-url/", "").replace(".pdf", "");
            const div = document.createElement("div");
            div.className = "vatprc";
            div.style.display = "inline";
            elem.parentNode.insertBefore(div, elem.nextElementSibling);
            htmPreact.render(htmPreact.html`<${LockButton} short=${short} user=${user} />`, div);
          });
        }, {
          onlyStream: true,
          id: "discourse-attachment-pdf-secure-component"
        });
      });
    }
  };
});